// ActiveFilters.tsx
import React, { useState } from 'react';
import {
  Button,
  SpaceBetween,
  ExpandableSection,
  Badge,
  Box,
  Modal,
  Input,
  Container,
} from '@cloudscape-design/components';
import { FilterModel } from 'ag-grid-community';
import { FilterSet } from './FilterSet';

interface ActiveFiltersProps {
  filters: FilterModel;
  onClearFilter: (column: string) => void;
  onClearAllFilters: () => void;
}

export const ActiveFilters: React.FC<ActiveFiltersProps> = ({
  filters,
  onClearFilter,
  onClearAllFilters,
}) => {
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [filterSetName, setFilterSetName] = useState('');
  const filterCount = Object.keys(filters).length;

  const getFilterDisplayValue = (filter: any): string => {
    if (typeof filter === 'string') {
      return filter;
    }
    if (filter && typeof filter === 'object') {
      if ('filter' in filter) {
        return filter.filter;
      }
      if ('value' in filter) {
        return filter.value;
      }
    }
    return JSON.stringify(filter);
  };

  return (
    <SpaceBetween direction="vertical" size="s">
      {filterCount > 0 && (
        <ExpandableSection
          variant="footer"
          headerText={
            <SpaceBetween direction="horizontal" size="xs">
              <span>Active Filters</span>
              <Badge color="blue">{filterCount}</Badge>
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="s">
            <Container>
              <SpaceBetween direction="horizontal" size="xs">
                {Object.entries(filters).map(([column, filter]) => (
                  <Box key={column}>
                    <SpaceBetween direction="horizontal" size="xs">
                      <span>
                        {column}:{' '}
                        <span style={{ color: '#1D8102' }}>{getFilterDisplayValue(filter)}</span>
                      </span>
                      <Button
                        variant="icon"
                        iconName="remove"
                        onClick={() => onClearFilter(column)}
                        ariaLabel={`Remove ${column} filter`}
                      />
                    </SpaceBetween>
                  </Box>
                ))}
                <Button onClick={() => setIsSaveModalVisible(true)} iconName="status-positive">
                  Save as Filter Set
                </Button>
                <Button onClick={onClearAllFilters} iconName="remove">
                  Clear All
                </Button>
              </SpaceBetween>
            </Container>
          </SpaceBetween>
        </ExpandableSection>
      )}

      <Modal
        visible={isSaveModalVisible}
        onDismiss={() => setIsSaveModalVisible(false)}
        header="Save Filter Set"
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setIsSaveModalVisible(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  console.log('Saving filter set:', filterSetName);
                  setIsSaveModalVisible(false);
                  setFilterSetName('');
                }}
              >
                Save
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <SpaceBetween direction="vertical" size="m">
          <Input
            value={filterSetName}
            onChange={({ detail }) => setFilterSetName(detail.value)}
            placeholder="Enter filter set name"
          />
        </SpaceBetween>
      </Modal>
    </SpaceBetween>
  );
};
