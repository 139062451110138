import { ColDef, GridOptions, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import { AttributeMetadata } from '@amzn/chub-model-typescript-client';
//import './styles.scss';

/**
 * Creates column definitions for an AG Grid based on attribute metadata
 * @param controlAttributesData - Contains metadata for grid columns/attributes
 * @param editedCells - Set containing IDs of cells that have been modified
 * @param CustomCellPopoverRenderer - Custom renderer component for modal view cells
 * @returns Array of column definitions for AG Grid
 */
export const createColumnDefs = (
  controlAttributesData: any,
  editedCells: Set<string>,
  CustomCellPopoverRenderer: any
): ColDef[] => {
  if (!controlAttributesData) return [];
  const multilineColumns = [
    'Classification Reason',
    'Cost Center Comments',
    'Finance Comments PQ',
    'Accounting Comments PQ',
    'Finance Comments',
    'Accounting Comments',
  ];

  return (
    controlAttributesData.attributesMetadata
      ?.map((attr: AttributeMetadata): ColDef | null => {
        const fieldName = attr.name;
        if (!fieldName) {
          console.warn('Attribute name is undefined', attr);
          return null;
        }

        const baseColumnConfig = {
          field: fieldName,
          headerName: attr.displayName || fieldName,
          editable: attr.isEditable || false,
          minWidth: 180,
          flex: 1,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          hide: !attr.isVisibleByDefault,
        };

        // Enhanced configuration for multiline columns
        if (multilineColumns.includes(fieldName)) {
          return {
            ...baseColumnConfig,
            minWidth: 450,
            wrapText: true,
            autoHeight: true,
            cellRenderer:
              fieldName === 'Classification Reason'
                ? (params: any) => {
                    if (!params.value) return '';
                    const paragraphs = params.value
                      .split('</p>')
                      .map((p: string) => p.replace(/<p>/g, '').trim())
                      .filter((p: string) => p.length > 0);
                    return paragraphs.join('\n');
                  }
                : CustomCellPopoverRenderer,
            cellStyle: (params: any) => ({
              whiteSpace: 'pre-line',
              padding: '4px 8px',
              lineHeight: '1.2',
              ...(editedCells.has(`${params.node.id}-${params.column.colId}`)
                ? { backgroundColor: '#5CA3EE' }
                : {}),
            }),
          };
        }

        // Standard configuration for other columns
        return {
          ...baseColumnConfig,
          cellDataType: attr.dataType === 'java.lang.Boolean' ? 'boolean' : 'text',
          cellEditor:
            attr.dataType === 'java.lang.Boolean'
              ? 'agCheckboxCellEditor'
              : attr.dataType === 'java.util.List'
                ? 'agSelectCellEditor'
                : 'agTextCellEditor',
          cellRenderer:
            attr.dataType === 'java.lang.Boolean'
              ? 'agCheckboxCellRenderer'
              : attr.enableModalView
                ? CustomCellPopoverRenderer
                : undefined,
          cellEditorParams:
            attr.dataType === 'java.util.List'
              ? {
                  values: ['Yes', 'No'],
                }
              : undefined,
          valueGetter:
            attr.dataType === 'java.lang.Boolean'
              ? (params: ValueGetterParams) => {
                  const value = (params.data as Record<string, any>)?.[fieldName];
                  return value === true || value === 'true';
                }
              : undefined,
          cellStyle: (params: any) => ({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: '4px',
            ...(editedCells.has(`${params.node.id}-${params.column.colId}`)
              ? { backgroundColor: '#5CA3EE' }
              : {}),
          }),
        };
      })
      .filter((colDef: ColDef | null): colDef is ColDef => colDef !== null) ?? []
  );
};

/**
 * Creates grid options configuration for AG Grid
 * @param defaultColDef - Default column properties
 * @param rowData - Array of data to be displayed in the grid
 * @param columnDefs - Column definitions for the grid
 * @param onCellValueChanged - Callback function for cell value changes
 * @param onGridReady - Callback function when grid is initialized
 * @returns GridOptions configuration object
 */
export const createGridOptions = (
  defaultColDef: any,
  rowData: any[],
  columnDefs: ColDef[],
  onCellValueChanged: any,
  onGridReady: any
): GridOptions => ({
  defaultColDef: {
    ...defaultColDef,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  },
  rowData,
  columnDefs,
  onCellValueChanged,
  suppressColumnVirtualisation: false,
  suppressRowClickSelection: true,
  pagination: true,
  paginationPageSize: 300,
  paginationPageSizeSelector: [100, 200, 300, 500],
  animateRows: true,
  rowSelection: 'multiple',
  ensureDomOrder: true,
  onGridReady,
  domLayout: 'normal',
  enableCellTextSelection: true,
  suppressScrollOnNewData: true,
  getRowHeight: undefined,
  // Add these for better performance with auto-height
  asyncTransactionWaitMillis: 100,
  suppressRowTransform: true,
});
