// FilterSet.tsx
import React from 'react';
import { ButtonDropdown } from '@cloudscape-design/components';

interface FilterSetProps {
  savedFilterSets: Array<{
    id: string;
    text: string;
    disabled: boolean;
  }>;
  onFilterSetSelect: (detail: any) => void;
}

export const FilterSet: React.FC<FilterSetProps> = ({ savedFilterSets, onFilterSetSelect }) => {
  return (
    <ButtonDropdown items={savedFilterSets} onItemClick={({ detail }) => onFilterSetSelect(detail)}>
      Current View
    </ButtonDropdown>
  );
};
