import { LicenseManager } from 'ag-grid-enterprise';

// Quote from ag-grid team:
// "If you are distributing your product and including AG Grid Enterprise, we realise that your license key will be visible to others.
// We appreciate that this is happening and just ask that you don't advertise it. Given our product is JavaScript, there is little we can do to prevent this."
export const AG_GRID_LICENSE =
  'Using_this_{AG_Grid}_Enterprise_key_{AG-074499}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Amazon.com_Services_LLC}_is_granted_a_{Multiple_Applications}_Developer_License_for_{20}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{27_December_2025}____[v3]_[01]_MTc2Njc5MzYwMDAwMA==06dad0105f1b196ef18f7fad5fbed373';

export const initializeAgGridLicense = () => {
  LicenseManager.setLicenseKey(AG_GRID_LICENSE);
};
