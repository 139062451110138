/**
 * Enum representing different stages of the application.
 * @enum {string}
 */
export enum Stages {
  ALPHA = 'alpha',
  BETA = 'beta',
  GAMMA = 'gamma',
  PROD = 'prod',
}

/**
 * Custom error class for API-related errors.
 * @extends Error
 */
export class ApiError extends Error {
  /** The HTTP status code associated with the error. */
  status: number;

  /**
   * Creates an instance of ApiError.
   * @param {number} status - The HTTP status code.
   * @param {string} message - The error message.
   */
  constructor(status: number, message: string) {
    super(message);
    this.name = 'ApiError';
    this.status = status;
  }
}

/**
 * Object containing API endpoint paths.
 * @type {Record<string, string>}
 */
export const ApiPaths: Record<string, string> = {
  getControls: '/GetControls',
  getControlAttributes: '/GetControlAttributes',
  getControlData: '/GetControlData',
  updateControlData: '/UpdateControlData',
};

export const NavigationPaths: Record<string, string> = {
  summary: '/summary',
  historical: '/historical',
};

/**
 * Determines the current stage based on the hostname.
 * @returns {string} The current stage.
 */
export const getCurrentStage = (): string => {
  const hostname = window.location.hostname;
  return hostname.split('.')[0];
};

/**
 * Retrieves the base URL for API calls based on the given stage.
 * @param {string} stage - The current stage.
 * @returns {string} The base URL for API calls.
 */
export const getBaseUrl = (stage: string): string => {
  if (stage === 'localhost') {
    return `https://gamma.chub-api.controllershiphub.finergy.amazon.dev`;
  } else return `https://${stage}.chub-api.controllershiphub.finergy.amazon.dev`;
};
