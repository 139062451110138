import React, { useState, useRef } from 'react';
import { Button, Popover } from '@cloudscape-design/components';
import {
  ICellRendererParams,
  FilterModel,
  GridApi,
  CellValueChangedEvent,
} from 'ag-grid-community';
import { RecordAttributes, ControlAttributesMetadata } from '@amzn/chub-model-typescript-client';
import { apiCall } from 'src/utils/ApiCall';
import { ApiPaths } from 'src/common/config/ApiConstants';

export interface FilterToken {
  propertyKey: string;
  operator: string;
  value: string;
}

export interface DefaultFilters {
  tokens: FilterToken[];
  operation: string;
}

export interface AutoPopulateConfig {
  sourceColumn: string;
  targetColumn: string;
  formatMessage: (params: {
    sourceValue: string;
    alias: string;
    timestamp: string;
    currentTargetValue?: string;
  }) => string;
}

/**
 * Creates an array of auto-populate configurations for grid columns
 * Defines how changes in source columns should update corresponding target columns
 * @returns {AutoPopulateConfig[]} Array of auto-populate configurations
 */
export const createAutoPopulateConfigs = (): AutoPopulateConfig[] => [
  {
    sourceColumn: 'Cost Center',
    targetColumn: 'Cost Center Comments',
    formatMessage: ({ sourceValue, alias, timestamp }) =>
      `[${alias}@][${timestamp}]:: Cost Center updated to: ${sourceValue}`,
  },
  {
    sourceColumn: 'Cost Center Comments',
    targetColumn: 'Cost Center Comments',
    formatMessage: ({ sourceValue, alias, timestamp }) =>
      `[${alias}@][${timestamp}]:: ${extractContent(sourceValue)}`,
  },
  {
    sourceColumn: 'Accounting Ack',
    targetColumn: 'Accounting Comments',
    formatMessage: ({ sourceValue, alias, timestamp }) => {
      return `[${alias}@][${timestamp}]`;
    },
  },
  // Add more configurations here as needed
];

/**
 * Handles automatic population of target cells based on changes in source cells
 * @param params - Event parameters from the cell value change
 * @param configs - Array of auto-populate configurations
 * @param alias - User identifier for tracking changes
 */
export const handleAutoPopulate = (
  params: CellValueChangedEvent,
  configs: AutoPopulateConfig[],
  alias: string
): void => {
  const config = configs.find((cfg) => cfg.sourceColumn === params.column.getColId());

  if (!config) return;

  const currentValue = params.node.data[config.targetColumn];
  const timestamp = formatTimestamp();

  // Only update if not already formatted or content is different
  if (!hasFormatting(currentValue) || !currentValue.includes(extractContent(params.newValue))) {
    const newValue = config.formatMessage({
      sourceValue: params.newValue,
      alias,
      timestamp,
      currentTargetValue: currentValue,
    });

    params.node.setDataValue(config.targetColumn, newValue);
  }
};

/**
 * Formats the current timestamp in ISO format
 * @returns {string} ISO formatted timestamp
 */
export const formatTimestamp = () => new Date().toISOString();

/**
 * Checks if a value contains the standard formatting pattern [alias@][timestamp]::
 * @param value - String to check for formatting
 * @returns {boolean} True if the value contains standard formatting
 */
export const hasFormatting = (value: string = ''): boolean =>
  value.startsWith('[') && value.includes('@][') && value.includes(']::');

/**
 * Extracts the content portion from a formatted string
 * @param value - Formatted string following pattern [alias@][timestamp]:: content
 * @returns {string} Extracted content or original value if no pattern match
 *
 * @example
 * extractContent("[user@][2023-12-20T10:00:00Z]:: Hello") // returns "Hello"
 * extractContent("Plain text") // returns "Plain text"
 */
export const extractContent = (value: string = ''): string => {
  const match = value.match(/\[.*@\]\[.*\]:: (.*)/);
  return match ? match[1] : value;
};

/**
 * Handles saving changes made to grid data
 * @param rowData - Array of row data from the grid
 * @param editedCells - Set of cell IDs that have been modified
 * @param controlAttributesData - Metadata about control attributes
 * @param controlId - Identifier for the control being updated
 * @throws Error if control dataset key is missing or no changes to save
 * @returns Promise that resolves when save is complete
 */
export const handleSaveChanges = async (
  rowData: Record<string, any>[],
  editedCells: Set<string>,
  controlAttributesData: ControlAttributesMetadata | null,
  controlId: string
): Promise<void> => {
  const controlDatasetKey = controlAttributesData?.controlDatasetKey;

  if (!controlDatasetKey) {
    throw new Error('Control dataset key is not available');
  }

  const controlKeyToUpdatesMap: { [key: string]: RecordAttributes[] } = {};

  rowData.forEach((row, rowIndex) => {
    const controlDatasetKeyValue = row[controlDatasetKey];
    const updatedAttributes: RecordAttributes[] = [];

    Object.entries(row).forEach(([key, value]) => {
      const cellId = `${rowIndex}-${key}`;
      if (editedCells.has(cellId)) {
        updatedAttributes.push({
          name: key,
          value: typeof value === 'boolean' ? value.toString() : (value as string),
        });
      }
    });

    if (updatedAttributes.length > 0) {
      controlKeyToUpdatesMap[controlDatasetKeyValue as string] = updatedAttributes;
    }
  });

  if (Object.keys(controlKeyToUpdatesMap).length === 0) {
    throw new Error('No changes to save');
  }

  const response = await apiCall<{ results: { name: string; status: string }[] }>({
    endpoint: `${ApiPaths.updateControlData}/${controlId}`,
    method: 'PATCH',
    body: { controlKeyToUpdatesMap },
  });

  if (!response.results?.every((result) => result.status === 'SUCCESS')) {
    const failedResults = response.results.filter((result) => result.status !== 'SUCCESS');
    throw new Error(`Save failed for: ${failedResults.map((r) => r.name).join(', ')}`);
  }
};

/**
 * Converts default filters to AG Grid filter model format
 * @param defaultFilters - Default filters configuration
 * @returns FilterModel compatible with AG Grid
 */
export const convertDefaultFiltersToAgGridModel = (defaultFilters: DefaultFilters): FilterModel => {
  const filterModel: FilterModel = {};
  defaultFilters.tokens.forEach((token) => {
    filterModel[token.propertyKey] = {
      filterType: 'multi',
      filterModels: [
        {
          filterType: 'text',
          type: token.operator,
          filter: token.value,
        },
      ],
    };
  });
  return filterModel;
};

/**
 * Parses default filters string and applies them to the grid
 * @param defaultFiltersString - JSON string containing default filters
 * @param gridApi - Reference to AG Grid API
 * @returns Applied filter model or empty object if parsing fails
 */
export const parseAndApplyDefaultFilters = (
  defaultFiltersString: string,
  gridApi: GridApi
): FilterModel => {
  try {
    const defaultFilters: DefaultFilters = JSON.parse(defaultFiltersString);
    if (defaultFilters.tokens?.length > 0) {
      const filterModel = convertDefaultFiltersToAgGridModel(defaultFilters);
      console.log('Applying filter model:', filterModel); // Debug log
      gridApi.setFilterModel(filterModel);
      return filterModel;
    }
  } catch (error) {
    console.error('Error parsing default filters:', error);
  }
  return {};
};

// A CustomCellPopoverRenderer component that handles truncated text with a popover
// TODO: Need to use this when we display show more
export const CustomCellPopoverRenderer = (props: ICellRendererParams) => {
  const popoverRef = useRef<HTMLElement>(null);
  const fullText = props.value as string;
  const truncatedText = fullText.length > 50 ? fullText.slice(0, 50) + '...' : fullText;

  return (
    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {truncatedText}
      {fullText.length > 50 && (
        <Popover
          dismissButton={true}
          position="right"
          size="large"
          triggerType="custom"
          content={
            <div
              style={{
                maxWidth: '400px',
                maxHeight: '400px',
                overflow: 'auto',
                padding: '10px',
              }}
            >
              {fullText}
            </div>
          }
          renderWithPortal
          ref={popoverRef}
        >
          <span
            style={{
              color: '#0972d3',
              cursor: 'pointer',
              textDecoration: 'none',
              marginLeft: '4px',
              fontSize: '13px',
            }}
          >
            show more
          </span>
        </Popover>
      )}
    </div>
  );
};

/**
 * Processes an array of control data records and transforms them into a simplified object structure.
 *
 * @param controlData - A two-dimensional array of RecordAttributes objects containing control data
 * @returns An array of objects where each object contains name-value pairs from the record set
 *
 * @example
 * const data = [[{ name: 'field1', value: 'value1' }, { name: 'field2', value: 'value2' }]]
 * const result = processControlData(data)
 * // Result: [{ field1: 'value1', field2: 'value2' }]
 */
export const processControlData = (controlData: RecordAttributes[][]): Record<string, any>[] => {
  return controlData.map((recordSet: RecordAttributes[]) =>
    recordSet.reduce(
      (acc, record) => {
        if (record.name) {
          acc[record.name] = record.value ?? '';
        }
        return acc;
      },
      {} as Record<string, string>
    )
  );
};

/**
 * Clears the filter for a specific column while maintaining other active filters.
 *
 * @param gridApi - The grid API instance
 * @param column - The name of the column whose filter should be cleared
 * @param activeFilters - The current filter model containing all active filters
 * @returns The updated filter model after removing the specified column filter
 *
 * @example
 * const updatedFilters = clearFilter(gridApi, 'columnName', currentFilters)
 */
export const clearFilter = (gridApi: GridApi, column: string, activeFilters: FilterModel) => {
  const currentFilterModel = { ...activeFilters };
  delete currentFilterModel[column];

  // If there are remaining filters, ensure they maintain the multi-filter structure
  Object.keys(currentFilterModel).forEach((key) => {
    if (currentFilterModel[key] && !currentFilterModel[key].filterType) {
      currentFilterModel[key] = {
        filterType: 'multi',
        filterModels: [
          {
            filterType: 'text',
            ...currentFilterModel[key],
          },
        ],
      };
    }
  });

  gridApi.setFilterModel(currentFilterModel);
  return currentFilterModel;
};

/**
 * Clears all active filters from the grid.
 *
 * @param gridApi - The grid API instance
 * @returns An empty object representing a cleared filter model
 *
 * @example
 * const emptyFilters = clearAllFilters(gridApi)
 */
export const clearAllFilters = (gridApi: GridApi) => {
  gridApi.setFilterModel(null);
  return {};
};
