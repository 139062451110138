import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Button, SpaceBetween, Box, Grid, ButtonDropdown } from '@cloudscape-design/components';
import { AgGridReact } from 'ag-grid-react';
import { initializeAgGridLicense } from 'src/utils/License';
import {
  GridApi,
  CellValueChangedEvent,
  GridReadyEvent,
  FilterChangedEvent,
  FilterModel,
} from 'ag-grid-community';
import {
  handleSaveChanges,
  parseAndApplyDefaultFilters,
  CustomCellPopoverRenderer,
  processControlData,
  clearFilter,
  clearAllFilters,
  createAutoPopulateConfigs,
  handleAutoPopulate as handleAutoPopulateUtil,
} from 'src/utils/ControlSummaryUtils';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { ControlAttributesMetadata, RecordAttributes } from '@amzn/chub-model-typescript-client';
import { ActiveFilters } from '../../common/ActiveFilter';
import { FilterSet } from '../../common/FilterSet';

import { useControls } from 'src/common/ControlContext';
import { getButtonDropdownItems } from 'src/common/ControlSummaryTableConfig';
import { createColumnDefs, createGridOptions } from '../../utils/GridUtils';
import { ModuleRegistry, AllCommunityModule } from 'ag-grid-community';
import { useUserAlias } from 'src/common/hooks/useUserAlias';
import {
  ColumnMenuModule,
  ColumnsToolPanelModule,
  ContextMenuModule,
  FiltersToolPanelModule,
  SetFilterModule,
  MultiFilterModule,
  SideBarModule,
  SideBarDef,
} from 'ag-grid-enterprise';

initializeAgGridLicense();
ModuleRegistry.registerModules([
  AllCommunityModule,
  MultiFilterModule,
  SetFilterModule,
  FiltersToolPanelModule,
  SideBarModule,
  ColumnMenuModule,
  ContextMenuModule,
  ColumnsToolPanelModule,
]);

interface TableUtilProps {
  controlData: RecordAttributes[][];
  controlAttributesData: ControlAttributesMetadata | null;
  isLoading: boolean;
  controlId: string;
}
// Custom component for showing Loading the data
export const CustomLoadingOverlay = () => {
  return (
    <div
      className="ag-overlay-loading-center"
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
    >
      <i className="fas fa-spinner fa-spin"></i>
      <span style={{ padding: '10px' }}>Loading data...</span>
    </div>
  );
};

/**
 * ControlSummaryPageAGGrid
 *
 * This component renders a data grid using the AG Grid library to display and manage control summary data.
 * It provides features like filtering, sorting, editing, and saving changes to the control data.
 *
 * @param {Object} props - The component props
 * @param {RecordAttributes[][]} props.controlData - The control data to be displayed in the grid
 * @param {ControlAttributesMetadata | null} props.controlAttributesData - Metadata for the control attributes
 * @param {boolean} props.isLoading - Indicates whether the data is currently being loaded
 * @param {string} props.controlId - The unique identifier for the control
 */
export const ControlSummaryPageAGGrid: React.FC<TableUtilProps> = ({
  controlData,
  controlAttributesData,
  isLoading,
  controlId,
}: {
  controlData: RecordAttributes[][];
  controlAttributesData: ControlAttributesMetadata | null;
  isLoading: boolean;
  controlId: string;
}) => {
  const [rowData, setRowData] = useState<Record<string, any>[]>([]);
  const [editedCells, setEditedCells] = useState<Set<string>>(new Set());
  const [activeFilters, setActiveFilters] = useState<FilterModel>({});
  const [quickFilterText, setQuickFilterText] = useState('');
  const gridApiRef = useRef<GridApi | null>(null);
  const { controls } = useControls();
  const { alias } = useUserAlias();
  const defaultFiltersAppliedRef = useRef(false);

  const control = useMemo(
    () => controls.find((c) => c.controlId === controlId) || null,
    [controls, controlId]
  );
  const dropdownItems = useMemo(() => getButtonDropdownItems(control), [control]);

  const columnDefs = useMemo(
    () => createColumnDefs(controlAttributesData, editedCells, CustomCellPopoverRenderer),
    [controlAttributesData, editedCells, CustomCellPopoverRenderer]
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: 'agMultiColumnFilter',
      filterParams: {
        maxNumConditions: 1,
      },
    }),
    []
  );

  // Autosizing columns
  // useEffect(() => {
  //   if (gridApiRef.current && rowData.length > 0) {
  //     setTimeout(() => {
  //       // First auto-size all columns
  //       const allColumnIds: string[] = [];
  //       gridApiRef.current?.getColumnDefs()?.forEach((column: any) => {
  //         allColumnIds.push(column.field);
  //       });
  //       gridApiRef.current?.autoSizeColumns(allColumnIds);
  //     }, 0);
  //   }
  // }, [rowData]);

  // Setting the Grid row data
  useEffect(() => {
    const processed = processControlData(controlData);
    setRowData(processed);
  }, [controlData]);

  // Get Configs for autopopulate logic for specific columns
  const autoPopulateConfigs = useMemo(() => createAutoPopulateConfigs(), []);

  // Handle autopopulate method using the function configured in autoPopulateConfigs
  const handleAutoPopulate = useCallback(
    (params: CellValueChangedEvent) => {
      handleAutoPopulateUtil(params, autoPopulateConfigs, alias);
    },
    [alias, autoPopulateConfigs]
  );

  const onCellValueChanged = useCallback(
    (params: CellValueChangedEvent) => {
      if (!params.node || params.oldValue === params.newValue) {
        return;
      }

      // Track edited cell
      const cellId = `${params.node.id}-${params.column.getColId()}`;
      setEditedCells((prev) => new Set(prev).add(cellId));

      // Handle auto populate
      handleAutoPopulate(params);

      // Auto-size only the changed column after a brief delay to ensure content is rendered
      // if (gridApiRef.current) {
      //   const columnId = params.column.getColId();
      //   gridApiRef.current.autoSizeColumns([columnId], false);
      // }
    },
    [handleAutoPopulate]
  );

  // Handles saving all changes made to the grid data
  // Clears the edited cells tracking after successful save
  // Displays success/error messages to the user
  const saveChanges = useCallback(async () => {
    try {
      await handleSaveChanges(rowData, editedCells, controlAttributesData, controlId);
      setEditedCells(new Set());
      alert('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert(error instanceof Error ? error.message : 'Failed to save changes. Please try again.');
    }
  }, [rowData, controlId, controlAttributesData, editedCells]);

  // Applies default filters to the grid based on controlAttributesData
  // Only applies filters once per grid instance using defaultFiltersAppliedRef
  const applyDefaultFilters = useCallback(() => {
    if (
      gridApiRef.current &&
      controlAttributesData?.defaultFilters &&
      !defaultFiltersAppliedRef.current
    ) {
      const filterModel = parseAndApplyDefaultFilters(
        controlAttributesData.defaultFilters,
        gridApiRef.current
      );
      setActiveFilters(filterModel);
      defaultFiltersAppliedRef.current = true;
    }
  }, [controlAttributesData]);

  const currentFilters = gridApiRef.current?.getFilterModel();
  console.log('Current Filters:', currentFilters);

  // Initializes the grid when it's ready
  // Stores the grid API reference and resets the default filters flag
  // Shows initial loading overlay
  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    defaultFiltersAppliedRef.current = false;

    // Show loading overlay initially
    params.api.showLoadingOverlay();
  }, []);

  // Effect hook to automatically apply default filters
  useEffect(() => {
    if (
      gridApiRef.current &&
      !isLoading &&
      rowData.length > 0 &&
      controlAttributesData?.defaultFilters &&
      !defaultFiltersAppliedRef.current
    ) {
      applyDefaultFilters();
    }
  }, [isLoading, rowData, controlAttributesData, applyDefaultFilters]);

  // Clears filters for a specific column
  // Updates the active filters state with the new filter model
  const handleClearFilter = useCallback(
    (column: string) => {
      if (gridApiRef.current) {
        const newFilters = clearFilter(gridApiRef.current, column, activeFilters);
        setActiveFilters(newFilters);
      }
    },
    [activeFilters]
  );

  // Clears all active filters from the grid
  // Resets the default filters applied flag
  const handleClearAllFilters = useCallback(() => {
    if (gridApiRef.current) {
      const newFilters = clearAllFilters(gridApiRef.current);
      setActiveFilters(newFilters);
      defaultFiltersAppliedRef.current = false;
    }
  }, []);

  // Updates the active filters state whenever filters change in the grid
  const onFilterChanged = useCallback((event: FilterChangedEvent) => {
    const filterModel = event.api.getFilterModel();
    console.log('Filter Model:', filterModel);
    // Transform if needed to match your ActiveFilters component expectations
    const processedFilters = Object.entries(filterModel).reduce((acc, [key, value]) => {
      if (value && 'filterModels' in value) {
        const firstFilter = value.filterModels[0];
        acc[key] = {
          type: firstFilter.type,
          filter: firstFilter.filter,
        };
      }
      return acc;
    }, {} as FilterModel);

    setActiveFilters(processedFilters);
  }, []);

  // Handles changes to the quick filter text input
  // Updates the quick filter text state used for grid-wide filtering
  const onQuickFilterTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuickFilterText(event.target.value);
  }, []);

  const savedFilterSets = [
    { id: '1', text: 'Current View 1', disabled: false },
    { id: '2', text: 'Current View 2', disabled: false },
  ];

  const handleFilterSetSelect = (detail: any) => {
    console.log('Selected filter set:', detail);
  };

  // Configure options passed on to the grid
  const gridOptions = useMemo(
    () => ({
      ...createGridOptions(defaultColDef, rowData, columnDefs, onCellValueChanged, onGridReady),
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: false,
              suppressColumnSelectAll: false,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressFilterSearch: false,
            },
          },
        ],
        defaultToolPanel: '',
        position: 'left', // explicitly type the position
      } as SideBarDef,
      loading: isLoading,
      loadingOverlayComponent: CustomLoadingOverlay,
      loadingOverlayComponentParams: {
        loadingMessage: 'Loading data...',
      },
      suppressLoadingOverlay: false,
      onFilterChanged: onFilterChanged,
      quickFilterText: quickFilterText,
    }),
    [
      defaultColDef,
      rowData,
      columnDefs,
      onCellValueChanged,
      onGridReady,
      onFilterChanged,
      CustomCellPopoverRenderer,
      quickFilterText,
      isLoading,
    ]
  );

  const onBtnExport = useCallback(() => {
    gridApiRef?.current?.exportDataAsCsv();
  }, []);
  return (
    <SpaceBetween direction="vertical" size="m">
      <div>
        <Grid
          gridDefinition={[
            { colspan: { default: 12, xxs: 9 } },
            { colspan: { default: 12, xxs: 3 } },
          ]}
        >
          <SpaceBetween direction="vertical" size="m">
            <SpaceBetween direction="horizontal" size="m">
              <FilterSet
                savedFilterSets={savedFilterSets}
                onFilterSetSelect={handleFilterSetSelect}
              />
              <input
                type="text"
                placeholder="Search here"
                value={quickFilterText}
                onChange={onQuickFilterTextChange}
                style={{
                  width: '100%',
                  padding: '8px',
                  fontSize: '14px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
              />
            </SpaceBetween>
            <ActiveFilters
              filters={activeFilters}
              onClearFilter={handleClearFilter}
              onClearAllFilters={handleClearAllFilters}
            />
          </SpaceBetween>
          <Box float="right">
            <Box margin={{ left: 'xs' }} display="inline-block" />
            <Button variant="inline-icon" iconName="download" onClick={onBtnExport}>
              Export
            </Button>
            <Box margin={{ left: 'xs' }} display="inline-block" />
            <ButtonDropdown items={dropdownItems}>References</ButtonDropdown>
            <Box margin={{ left: 'xs' }} display="inline-block" />
            <Button variant="primary" onClick={saveChanges}>
              Save Changes
            </Button>
          </Box>
        </Grid>
      </div>
      <div
        className="ag-theme-quartz"
        style={{
          height: 'calc(100vh - 255px)',
          ['--ag-header-background-color' as any]: '#eaeff4',
          ['--ag-header-foreground-color' as any]: 'black',
        }}
      >
        <AgGridReact {...gridOptions} />
      </div>
    </SpaceBetween>
  );
};
